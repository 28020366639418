<template>
  <b-overlay :show="isFetchingCmsSite">
    <b-row>
      <b-col class="text-right mb-2">
        <b-button
          variant="success"
          @click="$bvModal.show('cms-create-referral')"
        >
          New Referral
        </b-button>
      </b-col>
    </b-row>
    <b-table
      :fields="fields"
      :items="cmsReferrals"
      responsive
    >
      <template #cell(#)="data">
        <div>
          {{ data.index + 1 }}
        </div>
      </template>
      <template #cell(title)="data">
        <div>
          {{ data.value || '-' }}
        </div>
      </template>
      <template #cell(imageSrc)="data">
        <b-img
          v-if="data.value"
          :src="data.value"
          width="auto"
          height="150px"
        />
        <div v-else>
          -
        </div>
      </template>
      <template #cell(content)="data">
        <div
          v-if="data.value"
          v-html="data.value"
        />
        <div v-else>
          -
        </div>
      </template>
      <template #cell(id)="data">
        <div>
          <b-button
            size="sm"
            variant="danger"
            @click="onRemoveReferral(data.value)"
          >
            Remove
          </b-button>
        </div>
      </template>
    </b-table>
    <CreateCmsReferral />
  </b-overlay>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  components: {
    CreateCmsReferral: () => import('./create-referral.vue'),
  },
  data() {
    return {
      fields: [
        '#',
        {
          key: 'title',
          label: 'Title',
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'imageSrc',
          label: 'Image',
        },
        {
          key: 'content',
          label: 'Content',
        },
        {
          key: 'position',
          label: 'Position',
        },
        {
          key: 'id',
          label: 'Actions',
          class: 'text-right',
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['cmsReferrals', 'siteId']),
    ...mapState({
      isFetchingCmsSite: (state) => state.cms.isFetchingCmsSite,
    }),
  },
  methods: {
    ...mapActions(['removeCmsReferral']),
    onRemoveReferral(referralId) {
      this.removeCmsReferral({ siteId: this.siteId, referralId })
    },
  },
}
</script>
